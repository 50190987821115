
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs,
  unref
} from 'vue';
import { useRoute, LocationQuery, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ElForm, ElMessage, ElMessageBox, ElInput } from 'element-plus';

import LangSelect from '@/components/lang_select/Index.vue';
// import SocialSign from './components/SocialSignin.vue';
import { useStore } from '@/store';
import { UserActionTypes } from '@/store/modules/user/action-types';
import { ResponseError, googleOtpauth, googleVerify } from '@/services/api';

const LOGIN_RULES = {
  username: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  password: [
    {
      required: true,
      trigger: 'blur'
    }
  ]
};

export default defineComponent({
  components: {
    LangSelect
    // SocialSign
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const userNameInputRef = ref<typeof ElInput | null>(null);
    const passwordInputRef = ref < typeof ElInput | null>(null);
    const loginFormRef = ref<typeof ElForm | null>(null);

    const loginFormData = reactive({
      username: '',
      password: ''
    });
    const isLoading = ref(false);

    const state = reactive({
      passwordType: 'password',
      showDialog: false,
      capsTooltip: false,
      redirect: '',
      otherQuery: {}
    });

    const checkCapsLock = (e: KeyboardEvent) => {
      const { key } = e;
      if (key) {
        state.capsTooltip = key !== null && key.length === 1 && key >= 'A' && key <= 'Z';
      }
    };

    const showPwd = () => {
      if (state.passwordType === 'password') {
        state.passwordType = '';
      } else {
        state.passwordType = 'password';
      }

      nextTick(() => {
        passwordInputRef.value && passwordInputRef.value.focus();
      });
    };

    const Logo = `https://www.modelmedia${process.env.VUE_APP_THEME}.com/images/logo/theme-${process.env.VUE_APP_THEME}/logo-dark.png`;

    function login(e: any) {
      const loginForm = unref(loginFormRef);

      e.target.blur();
      loginForm && loginForm.validate(async(valid: boolean) => {
        if (valid) {
          isLoading.value = true;

          try {
            const otpauth = googleOtpauth({ data: { username: loginFormData.username, password: loginFormData.password } });
            otpauth.then((res) => {
              if (res.data.success === false) {
                ElMessage({
                  type: 'warning',
                  message: 'Wrong username or password!'
                });

                isLoading.value = false;
                return;
              }

              const key = res.data.key;

              ElMessageBox.prompt(res.data.qr_code ? '<div class="qrcode-container"><img class="qrcode-img" src="http://chart.apis.google.com/chart?cht=qr&chl=' + res.data.qr_code + '&chs=100" alt="" /></div>' : '', '2FA Code', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel'
                // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
                // inputErrorMessage: 'Invalid Email'
              })
                .then(async function(data): Promise<void> {
                  const token = data.value;

                  googleVerify({ data: { username: loginFormData.username, key: key, token: token } })
                    .then(async(res) => {
                      if (res.data.success === false) {
                        ElMessage({
                          type: 'warning',
                          message: 'Wrong verifiction code!'
                        });

                        isLoading.value = false;
                        return;
                      }

                      await store.dispatch(UserActionTypes.ACTION_LOGIN, loginFormData);

                      router
                        .push({
                          path: state.redirect || '/',
                          query: state.otherQuery
                        })
                        .catch(err => {
                          console.warn(err);
                        });
                    })
                    .catch((res) => {
                      isLoading.value = false;
                    });
                })
                .catch(() => {
                  isLoading.value = false;
                });
            });
          } catch (error) {
            ElMessage.error({
              message: (error as ResponseError).response?.data.message,
              type: 'error'
            });

            isLoading.value = false;
            loginForm.resetFields();
          }
        }
      });
    }

    function getOtherQuery(query: LocationQuery) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {} as LocationQuery);
    }

    watch(() => route.query, query => {
      if (query) {
        state.redirect = query.redirect?.toString() ?? '';
        state.otherQuery = getOtherQuery(query);
      }
    });

    onMounted(() => {
      if (loginFormData.username === '') {
        userNameInputRef.value && userNameInputRef.value.focus();
      } else if (loginFormData.password === '') {
        passwordInputRef.value && passwordInputRef.value.focus();
      }
    });

    return {
      userNameInputRef,
      passwordInputRef,
      loginFormRef,

      loginFormData,
      LOGIN_RULES,
      isLoading,

      checkCapsLock,
      showPwd,
      login,
      Logo,

      ...toRefs(state),
      t
    };
  }
});
